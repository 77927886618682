const influencerData = {
    "nano-influencers": {
      title: "Nano Influencers",
      overview: "We've saved you an exclusive seat at a late-night dinner that promises to be an experience like no other. Hosted by the iconic PartyNextDoor and backed by our exclusive partner Loud Clothing, this is a rare, invite-only opportunity to join a select few for an evening where every detail has been meticulously curated just for you. Expect a vibe that's made for sharing, with moments that will live far beyond the night.",
      highlights: [
        "Culinary Experience: Indulge in creative, delicious dishes, with every course crafted to elevate your palate.",
        "Private Entertainment: Immerse yourself in intimate performances and surprise moments, with PartyNextDoor hosting the night.",
        "Live Band: Enjoy a specially curated live music set that will provide the perfect backdrop to the evening.",
        "Unforgettable Moments: From cocktails to conversations, every moment is designed for capturing and sharing—because nights like this don't come often.",
        "Bottomless Cocktails: Sip on an endless array of expertly crafted cocktails, each one a perfect complement to the night's festivities."
      ],
      deliverables: [
        "1 Reel or TikTok."
      ]
    },
    "micro-influencers": {
      title: "Micro Influencers",
      overview: "Your next big experience is here. Hosted by PartyNextDoor and sponsored by Loud Clothing, this exclusive late-night dinner pairs curated gourmet dishes with bottomless cocktails. It's a once-in-a-lifetime opportunity to be part of an event that is as unforgettable as it is rare.",
      highlights: [
        "Gourmet Menu: A culinary adventure featuring bold, inventive flavors and exquisite pairings.",
        "Immersive Entertainment: Experience private performances with PartyNextDoor hosting at the heart of the evening.",
        "Live Band: A carefully selected live band will enhance the energy of the night, providing an unparalleled musical experience.",
        "Instagrammable Moments: From the setting to the surprises, every detail is crafted for you to capture and share.",
        "Bottomless Cocktails: Indulge in an endless flow of premium cocktails, each one a masterpiece designed to tantalize your taste buds and elevate your experience."
      ],
      deliverables: [
        "3 Posts.",
        "1 Snapchat Story."
      ]
    },
    "mid-level-influencers": {
      title: "Mid-Level Influencers",
      overview: "We're giving you the VIP treatment: all travel covered, a stay at a hotel, and an invitation to an exclusive dinner hosted by PartyNextDoor and sponsored by Loud Clothing. This isn't just an event—it's your chance to spend the night alongside PartyNextDoor, feeling like you're part of his inner circle. Curated moments, gourmet cuisine, and rare experiences await.",
      highlights: [
        "Luxury Dining Experience: Savor a menu crafted by top chefs, paired with bottomless premium cocktails. This dinner is designed to elevate your senses and inspire unforgettable content.",
        "Inner Circle Access: Spend the evening close to PartyNextDoor, enjoying the event as if you're part of his personal crew for the night.",
        "Live Band: A live band will create the perfect atmosphere for this exclusive gathering, setting the tone for an extraordinary night.",
        "Once-in-a-Lifetime Moments: From intimate conversations to VIP experiences, every moment is crafted for exclusivity, giving you content your audience will love.",
        "Bottomless Cocktails: Enjoy an unlimited selection of bespoke cocktails, each one a work of art that perfectly captures the essence of this extraordinary night."
      ],
      deliverables: [
        "3 Posts.",
        "1 Snapchat Story."
      ]
    },
    "high-level-influencers": {
      title: "High-Level Influencers",
      overview: "You're invited to the ultimate VIP evening. With travel and hotel accommodations fully covered, this is your chance to experience an exclusive night hosted by PartyNextDoor and sponsored by Loud Clothing. You'll spend the evening right alongside him, immersing yourself in a world of luxury and exclusivity.",
      highlights: [
        "Gourmet Dining Experience: Indulge in a multi-course, luxury dining experience paired with premium cocktails, designed to elevate every aspect of the evening.",
        "Right by the Star: Enjoy an intimate evening with PartyNextDoor, where you'll feel like part of his close circle throughout the night.",
        "Live Music: The evening's energy will be set by a live band, creating the perfect backdrop for this exclusive, once-in-a-lifetime experience.",
        "Elite Moments: Capture and share exclusive moments, from personal interactions to once-in-a-lifetime experiences that few will ever get to see.",
        "Bottomless Cocktails: Immerse yourself in a world of flavor with an endless stream of artisanal cocktails, each one crafted to perfection and tailored to enhance your VIP experience."
      ],
      deliverables: [
        "2 Posts.",
        "1 Snapchat Story."
      ]
    }
  };
  
  export default influencerData;