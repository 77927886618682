import React, {useState} from 'react';
import {LinkedCatalogue} from "../../products/components/index.js";

const CheckoutMenuSection = ({ products }) => {

    const [selectedMenus, setSelectedMenus] = useState([]);
    const [numberOfPeople, setNumberOfPeople] = useState(2);

    return (
        <>
            {products.map((product, index) => (
                <LinkedCatalogue
                    key={index}
                    product={product}
                    selectedMenus={selectedMenus}
                    setSelectedMenus={setSelectedMenus}
                    numberOfPeople={numberOfPeople}
                />
            ))}
        </>
    );
};


export default CheckoutMenuSection;