import React from 'react'
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { convertToCurrency } from '../../../../js/helpers/helpers'
import {CATEGORY_TABLE} from "../../products/js/utils/productUtils.js";

const slideUp = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;


const InfoItem = styled(motion.div)`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 20px;
max-height: 20px;
height: fit-content;
font-size: 0.85em;
box-sizing: border-box;

border-radius: var(--border-radius-default);
backdrop-filter: blur(10px);
background-color: rgba(0,0,0,0.1);
border: solid 0.5px white;
color:var(--text-colour);
transition: transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55),
background-color 1s ease-in-out;

&:active {
  transform: rotate(2deg);
}

&#total {
    background-color: whitesmoke;
    color: var(--accent-colour);
    border: solid 0.5px white;
    cursor: pointer;
    font-style: '900';
    animation: none;
    transform: none;
    /* background-color: transparent; */
    font-weight: 800;
    margin-top: 10px;
    backdrop-filter: blur(10px);
}

@media screen and (min-width: 768px) {
    max-width: 100%
}

@media screen and (min-width: 1920px) {
    max-width: 21rem;
    padding: 1rem;
    font-size: 1rem;
}

`

const Info = styled.span`
    font-weight: normal;
    font-size: 10px;
`

const BasketItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    gap: 5px;
`

function BasketItem({id, quantity, name, info, value, style, onClick, addons, item, collapsed = false}) {

    const newStyle = {
        ...style,
        // display: collapsed ? 'none' : 'flex',
    }


  return (
      <BasketItemContainer>
          <InfoItem id={id} style={newStyle} onClick={onClick}>
              {item?.item?.category !== CATEGORY_TABLE &&  <span>{quantity && `${quantity}x`} {name}</span> }
              {item?.item?.category === CATEGORY_TABLE &&  <span>{name} for {quantity} people</span> }

              <Info>{info}</Info>
              <span>{convertToCurrency(value)}</span>
          </InfoItem>

            {addons && addons.map((addon, index) => {
                return (
                    <InfoItem key={index} style={newStyle}>
                        <span>{addon.quantity && `${addon.quantity}x`} {addon.item.name}</span>
                        <span>{convertToCurrency(addon.sub_total)}</span>
                    </InfoItem>
                )
            })}
      </BasketItemContainer>

  )
}

export default BasketItem

