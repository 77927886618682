import React from 'react'
import styled from 'styled-components';

const ParagraphStyle = styled.p`
  z-index: 2;
  margin: 0;
  margin-bottom: 7px;
  box-sizing: border-box;
  color: var(--text-colour);

`

export const Paragraph = ({ children, style, content, onclick, onClick, id }) => {

  return (
    <ParagraphStyle id={id} onClick={onclick} style={style}>{content || children}</ParagraphStyle>
  )
}
