import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {FullPageAbsoluteContainer} from "../../../layout/styles/layoutStyles.js";
import SliderComponent from "../../../components/ui/SliderComponent.jsx";
import styled from "styled-components";
import {IntroSection} from "../../proposals/pages/ProposalsPage.jsx";
import {FloatingNav} from "../../../layout/components/FloatingNav/index.jsx";
import {Paragraph} from "../../../components/ui/typography/index.js";
import {convertToCurrency} from "../../../js/helpers/helpers.js";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {Footer} from "../../../layout/components/Footer.jsx";
import {getProductCollection} from "../../ecommerce/products/js/api/getProduct.js";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {ProductList} from "../../ecommerce/products/components/index.js";
import ProductCollection from '../../collections/components/ProductCollection.jsx';
import { Divider } from '../../../components/ui/Divider.jsx';


const Container = styled.div`
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
`

const Section = styled.section`
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    
    strong {
        color: var(--accent-colour);
    }
`

const CampaignPurchaseLandingPage = ({}) => {
    const minFollowers = 10000; // Minimum followers
    const maxFollowers = 25000000; // Maximum followers
    const maxBudget = 30000; // £30,000 for 25 million followers
    const costPerFollower = 0.0025// maxBudget / maxFollowers; // This will be 0.0012
    const instagramEngagementRate = 0.01; // 1% engagement rate for Instagram
    const tiktokEngagementRate = 0.18; // 18% engagement rate for TikTok

    const [instagramFollowers, setInstagramFollowers] = useState(minFollowers);
    const [tiktokFollowers, setTiktokFollowers] = useState(minFollowers);

    const calculateStats = (followers, engagementRate) => {
        const budget = followers * costPerFollower;
        const engagedUsers = Math.floor(followers * engagementRate);
        return { followers, budget, engagedUsers };
    };

    const instagramStats = useMemo(() => calculateStats(instagramFollowers, instagramEngagementRate), [instagramFollowers]);
    const tiktokStats = useMemo(() => calculateStats(tiktokFollowers, tiktokEngagementRate), [tiktokFollowers]);

    const handleInstagramFollowersChange = (newValue) => {
        setInstagramFollowers(newValue);
    };

    const handleTikTokFollowersChange = (newValue) => {
        setTiktokFollowers(newValue);
    };

    const {data, isLoading, error, refetch} = useApiQuery('collection/1', null, null, {
        enabled: false, // Disable automatic fetching
    });

    useEffect(() => {
        // Fetch data only once when the component mounts
        refetch();
    }, [refetch]);

    return (
        <Container>
            {/* Section 1: Instagram Followers Slider */}
            <Section>
                <h2>Instagram Campaign</h2>
                <SliderComponent
                    type={'number'}
                    value={instagramFollowers}
                    max={maxFollowers}
                    min={minFollowers}
                    step={1000}
                    onChange={handleInstagramFollowersChange}
                />
                <Paragraph>
                    With an Instagram reach of <strong>{instagramFollowers.toLocaleString()}</strong> followers.
                    The estimated cost for this campaign is <strong>£{(instagramStats.budget.toLocaleString())}</strong>.
                    We estimate <strong>{instagramStats.engagedUsers.toLocaleString()}</strong> engaged users. 
                    Based on a <strong>{instagramEngagementRate * 100}% engagement rate</strong>.
                </Paragraph>

            </Section>

            {/* Section 2: TikTok Followers Slider */}
            <Section>
                <h2>TikTok Campaign</h2>
                <SliderComponent
                    type={'number'}
                    value={tiktokFollowers}
                    max={maxFollowers}
                    min={minFollowers}
                    step={1000}
                    onChange={handleTikTokFollowersChange}
                />
                <Paragraph>
                    With a TikTok reach of <strong>{tiktokFollowers.toLocaleString()}</strong> followers.
                </Paragraph>
                <Paragraph>
                    We estimate <strong>{tiktokStats.engagedUsers.toLocaleString()}</strong> engaged users.
                </Paragraph>
                <Paragraph>
                    The estimated cost for this campaign is <strong>{convertToCurrency(tiktokStats.budget)}</strong>.
                </Paragraph>
            </Section>

            <Section>
                <Paragraph>
                    Our calculations are based on a cost of 0.25p per engaged user. The engagement rates used are 1% for Instagram and 18% for TikTok, based on recent industry data. Sources: Hootsuite Blog (2023) for Instagram data, IGface (2023) for TikTok data. These figures are averages and actual results may vary based on content quality, audience, and platform algorithms.
                </Paragraph>
            </Section>

            {/* Section 3: Deliverables */}
            <Section>
                {isLoading && <p>Loading products...</p>}
                {error && <p>Error: {error.message}</p>}
                {<ProductCollection data={data?.data} />}
                <ButtonComponent
                    variant={'solid'}
                    text={`Invest ${convertToCurrency(instagramStats.budget + tiktokStats.budget)}`}
                />
            </Section>

            <FloatingNav items={[]} />
        </Container>
    );
};

CampaignPurchaseLandingPage.propTypes = {
    
};

export default CampaignPurchaseLandingPage;
