import { APP_THEME } from "./theme";

export function changeCSSVariableProperty(variable, value) {
      document.documentElement.style.setProperty(variable, value);
}

export function getCSSVariableValue(variable) {
      const value = document.documentElement.style.getPropertyValue(variable);
      if(!value) return 'variable does not exist or has no value'
      return value;
}

export function changeHtmlBackground(colour, image) {
      const HtmlElement = document.querySelector('html');
      HtmlElement.style.backgroundColor = colour
      HtmlElement.style.backgroundImage = image
  }
  
export const updateTheme = (theme, backgroundImage, backgroundColour) => {

      changeCSSVariableProperty('--primary-colour', theme?.primary_colour || APP_THEME.primaryColour);
      changeCSSVariableProperty('--default-primary-colour', theme?.primary_colour || APP_THEME.primaryColour);
      changeCSSVariableProperty('--accent-colour', theme?.accent_colour || APP_THEME.AccentColour);
      changeCSSVariableProperty('--default-accent-colour', theme?.accent_colour || APP_THEME.AccentColour);
      changeCSSVariableProperty('--ticket-section-bg-colour', theme?.ticket_section_bg_colour || 'var(--primary-colour)');
    changeCSSVariableProperty('--ticket-item-bg-colour', theme?.ticket_item_bg_colour || 'var(--primary-colour)');

      changeCSSVariableProperty('--featured-section-bg-colour', theme?.featured_section_bg_colour || 'var(--accent-colour)');
      changeCSSVariableProperty('--header-colour', theme?.header_colour || theme?.featured_section_bg_colour || 'var(--default-header-colour)');
      changeCSSVariableProperty('--icon-colour', theme?.icon_colour || 'var(--default-icon-colour)');
      changeCSSVariableProperty('--text-colour', theme?.text_colour || 'white');
      changeCSSVariableProperty('--menu-bg-colour', theme?.menu_colour || theme?.accent_colour ||'var(--default-menu-colour)');
      changeCSSVariableProperty('--theme', theme?.theme_mode || theme?.theme_mode || 'dark');

      // change the background if set 
      backgroundImage || backgroundColour && changeHtmlBackground(backgroundImage, backgroundColour)

}

export function extractColorCode(str) {

    if(!str) return;

      // Check if the string includes 'linear-gradient'
      if (str.includes('linear-gradient')) {
        // Use a regular expression to find the first color code
        const match = str.match(/#([0-9A-F]{6})/i);
        if (match) {
          // Return the matched color code including the hashtag
          return '#' + match[1];
        }
      }
      // Return the original string if no linear gradient or no match is found
      return str;
    }

export function extractColorCodeFromCssVariable($variableName) {
      const color = getComputedStyle(document.documentElement).getPropertyValue($variableName).trim();
      return extractColorCode(color);
}
    
    // Example usage
    const gradientString = 'linear-gradient(to left, #6848d1, #cc3652)';
