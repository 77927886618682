import {useEcommerce} from "../../../js/context/EcommerceContext"
import axios from "axios";
import {API_URL, scrollToElement} from "../../../../../js/Helper";
import {useHandleError} from "../../../../error-handling/js/hooks/index.js";
import {useContext} from "react";
import {AuthContext} from "../../../../authentication/index.js";
import {setUser} from "@sentry/react";

export const useHandleStripePayment = () => {
    const { updateConfig } = useEcommerce();
    const { showErrorToast } = useHandleError();
    const { setUser, setIsAuthenticated, setToken } = useContext(AuthContext)

    /**
     *
     * @param isPaymentAllowed
     * @param isAuthenticated
     * @returns {boolean}
     */

    const isPaymentAllowed = (isPaymentAllowed, isAuthenticated) => {

        if(isAuthenticated) return true;
        if(isPaymentAllowed) return true;

        scrollToElement('order-summary')
        throw new Error('Please log in or fill in your details.');

    }

    /**
     *
     * @param hasQuestions
     * @param questionsComplete
     * @returns {boolean}
     */
    const hasCompletedQuestions = (hasQuestions, questionsComplete) => {

        if(hasQuestions === true && questionsComplete !== true) {
            scrollToElement('questions')
            throw new Error('Not completed questions');
        }
        
        return true;
    }

    /**
     *
     * @param elements
     * @returns {Promise<boolean>}
     */
    
    const formValidation = async (elements) => {
        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();

        if (submitError) {
            throw new Error(submitError.message);
        }

        return true;
    }

    /**
     *
     * @param intent
     * @returns {Promise<{clientSecret: string, message, user, order}>}
     */
    const getClientSecret = async (intent) => {

        if(!intent) {
            console.error('Payment Intent must be provided')
            throw new Error("Payment Intent must be provided")
        }

        try {
            const res = await axios.post(`${API_URL}/stripe/payment_intent/${intent}/client_secret`);

            updateConfig({
                confirmationMessage: res.data.message,
                order: res.data.order
            })

            console.log(res)

            if(res.data.status === 'error') {
                throw new Error(res.data.message)
            }

            return {
                clientSecret: res.data.client_secret,
                order: res.data.order,
                user: res.data.user,
                message: res.data.message
            }

        } catch (error) {
            console.error("Error fetching client secret:", error);
            const code = error.status ?? error.response?.status ?? error.code ?? error.response?.code ?? 500;

            if(code === 401) {
                setUser(null)
                setIsAuthenticated(false)
                setToken(null)

                throw  new Error('Please log in to complete payment');
            }

            const message = error.response.data.message ?? error?.statusText ?? error?.message ?? 'Error completing payment';
            // showErrorToast(message)

            throw new Error(message);
        }
    }


    /**
     *
     * @param clientSecret
     * @param order
     * @param user
     * @param stripe
     * @param elements
     * @returns {Promise<void>}
     */
    const confirmPayment = async (clientSecret, order, user, stripe, elements) => {

        const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret,
            confirmParams: {
            return_url: `${window.location.origin}/confirmation/${order.reference}`,
            payment_method_data: {
                billing_details: {
                name: `${user?.first_name} ${user?.last_name}`,
                email: user?.email,
                }
            },
            },
        });


        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
    
            console.error('Payment Attempt Failed: ' + error.message)
            showErrorToast(error)
            throw new Error(error.message || error);

        } else {


        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.

        }

    }


    return {
        confirmPayment,
        formValidation,
        getClientSecret,
        isPaymentAllowed,
        hasCompletedQuestions,
    }





}
