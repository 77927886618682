import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { convertToCurrency } from '../../js/helpers/helpers';

const SliderContainer = styled.div`
    width: 100%;
    padding: 20px 0;
`;

const StyledSlider = styled.input.attrs(props => ({
    style: {
        background: `linear-gradient(to right, var(--accent-colour) 0%, var(--accent-colour) ${(props.value - props.min) / (props.max - props.min) * 100}%, #ddd ${(props.value - props.min) / (props.max - props.min) * 100}%, #ddd 100%)`
    }
}))`
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;

    &:hover {
        opacity: 1;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: var(--accent-colour);
        box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.5);
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: var(--accent-colour);
        cursor: pointer;
    }
`;

const SliderInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    color: var(--accent-colour);
`;

const SliderValue = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: var(--accent-colour);
    text-align: center;
    margin-bottom: 10px;
`;

/**
 * Formats the value based on the specified type.
 * @param {number} value - The value to format.
 * @param {string} type - The type of formatting to apply.
 * @param {Object} options - Additional formatting options.
 * @returns {string} The formatted value.
 */
const formatValue = (value, type, options = {}) => {
    switch (type) {
        case 'currency':
            return convertToCurrency(value , 'currency', options.currency || 'GBP');
        case 'percentage':
            return `${value}%`;
        case 'number':
            return value.toLocaleString();
        case 'rating':
            return `${value} / ${options.max || 5}`;
        case 'time':
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        default:
            return value.toString();
    }
};

/**
 * A customizable slider component.
 *
 * @param {Object} props - The component props.
 * @param {number} props.min - The minimum value of the slider.
 * @param {number} props.max - The maximum value of the slider.
 * @param {number} props.value - The current value of the slider.
 * @param {function} props.onChange - Callback function when the slider value changes.
 * @param {string} [props.label] - Accessible label for the slider.
 * @param {number} [props.step=1] - Step increment for the slider.
 * @param {string} [props.orientation='horizontal'] - Orientation of the slider.
 * @param {string} [props.type='number'] - Type of value (currency, percentage, number, rating, time).
 * @param {Object} [props.typeOptions] - Additional options for the chosen type.
 * @param {Object} [props.style] - Custom styles for the slider.
 * @returns {React.Component} The SliderComponent.
 */
const SliderComponent = React.memo(({ 
    min, 
    max, 
    value, 
    onChange, 
    label = 'Slider',
    step = 1,
    orientation = 'horizontal',
    type = 'number',
    typeOptions = {},
    style = {}
}) => {
    // Internal state to handle immediate updates
    const [internalValue, setInternalValue] = useState(value);

    // Validate min, max, and value
    if (min >= max) {
        console.error('SliderComponent: min must be less than max');
        return null;
    }

    if (value < min || value > max) {
        console.warn('SliderComponent: value is outside of min-max range');
    }

    // Memoize the normalized value to prevent unnecessary recalculations
    const normalizedValue = useMemo(() => {
        return Math.min(Math.max(internalValue, min), max);
    }, [internalValue, min, max]);

    // Debounce the onChange handler
    const debouncedOnChange = useCallback(
        debounce((newValue) => {
            onChange(newValue);
        }, 50),
        [onChange]
    );

    const handleChange = (event) => {
        const newValue = Number(event.target.value);
        setInternalValue(newValue);
        debouncedOnChange(newValue);
    };

    const handleKeyDown = (event) => {
        // Implement keyboard navigation
        switch (event.key) {
            case 'ArrowRight':
            case 'ArrowUp':
                event.preventDefault();
                const incrementedValue = Math.min(normalizedValue + step, max);
                setInternalValue(incrementedValue);
                debouncedOnChange(incrementedValue);
                break;
            case 'ArrowLeft':
            case 'ArrowDown':
                event.preventDefault();
                const decrementedValue = Math.max(normalizedValue - step, min);
                setInternalValue(decrementedValue);
                debouncedOnChange(decrementedValue);
                break;
            default:
                break;
        }
    };

    const formattedValue = useMemo(() => {
        return formatValue(normalizedValue, type, typeOptions);
    }, [normalizedValue, type, typeOptions]);

    return (
        <SliderContainer>
            <SliderValue>{formattedValue}</SliderValue>
            <StyledSlider
                type="range"
                min={min}
                max={max}
                value={normalizedValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                step={step}
                aria-label={label}
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={normalizedValue}
                aria-orientation={orientation}
                style={style}
            />
            <SliderInfo>
                <span>{formatValue(min, type, typeOptions)}</span>
                <span>{formatValue(max, type, typeOptions)}</span>
            </SliderInfo>
        </SliderContainer>
    );
});

SliderComponent.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    step: PropTypes.number,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    type: PropTypes.oneOf(['currency', 'percentage', 'number', 'rating', 'time']),
    typeOptions: PropTypes.object,
    style: PropTypes.object
};

SliderComponent.displayName = 'SliderComponent';

export default SliderComponent;

// Utility function for debouncing
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
