import React from 'react'
import styled from 'styled-components';
import { LoadingAnimationComponent } from '../LoadingAnimationComponent';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    z-index: 1;
    // padding: 20px;

  @media screen and (min-width: 768px){
    max-width: var(--medium);
  }
  `

const Button = styled.button`
  display: inline-block;
  min-height: fit-content;
  padding: 10px 20px;
  font-weight: 800;
  font-size: 0.9em;
  min-width: 220px;
 
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  color: var(--text-colour);
  flex-grow: 1;
  z-index: 100;
  border-radius: var(--border-radius-default);
  backdrop-filter: blur(10px);
  appearance: none;
  border:none;
  &:hover {
    border: 2px solid white;
  }

  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
`;



const SolidButton = styled(Button)`
  color: var(--text-colour);
  background: ${props => props.isDisabled ? 'transparent' : "var(--accent-colour)"};
`;

const OutlinedButton = styled(Button)`
  border: none;
  outline: solid 1px white;
  background: transparent;
`;

const TertiaryButton = styled(Button)`
  border: none;
  background: none;
`

const IconSection = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: ${props => props.position == 'right' ? '10px' : ''};
    left: ${props => props.position == 'left' ? '10px' : ''};
    height: 100%;
    top: 0;
    
`
const variantMap = {
    'solid': SolidButton,
    'outlined': OutlinedButton,
    'tertiary': TertiaryButton
}

export const ButtonComponent = ({
  icon,
  text,
  style,
  colour,
  variant,
  onClick,
  children,
  isLoading,
  isLoadingText,
  isDisabled,
  iconPosition = 'right',
}) => {

    const ButtonVariant = variantMap[variant] || Button

    const styleVariant = {
        ...style,
        backgroundColor: isDisabled ? 'var(--disabled-colour)' : style?.color ?? colour,
        opacity: isDisabled ? 0.5 : 1,
    }

    let content = text || children;

    if(isLoading) {
        content = isLoadingText ? isLoadingText : content;
    }

  return (
    <Wrapper id={'button-wrapper'}>
        <ButtonVariant
        icon={!!icon}
        style={styleVariant}
        onClick={onClick}
        disabled={isDisabled}
        >

        { isLoading ? <LoadingAnimationComponent /> : content }

        </ButtonVariant>
        <IconSection position={iconPosition}>
          {/*{ isLoading ? <LoadingAnimationComponent /> : icon}*/}
        </IconSection>
    </Wrapper>
    )
}
