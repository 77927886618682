
export const CATEGORY_TABLE = 'table';

const createSingleArrayOfProductAndAddons = (product) => {
    // Ensure the product and its addons are valid
    if (!product || !Array.isArray(product.addons)) {
        console.warn('Product or addons are invalid', product)
        return product;
    }

    // Create an array with the main product and its addons
    return [product, ...product.addons]
};

const priceIncludingAddons = (product) => {
    // Ensure the product and its addons are valid
    if (!product || !Array.isArray(product.addons)) {
        return product.price
    }

    // Calculate the total price of the product and its addons
    return createSingleArrayOfProductAndAddons(product)
        .reduce((sub_total, item) => item.quantity * item.price, 0);

}

const productPrice = (product, quantity) => {
    // return product.paymentOption === 'deposit' ? product.depositAmount : product.price;
    return product.price;
}




export {
    createSingleArrayOfProductAndAddons,
    priceIncludingAddons,
    productPrice
}
/**
 * Calculate the price to pay for a product based on if paying deposit
 * requires authorisation or paying the full amount
 * @param product
 * @param quantity
 * @returns {*}
 */
