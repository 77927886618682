import React, {useContext, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import {setSessionStorageJSON} from '../../../js/Helper';
import {useCard} from '../js';
import {AuthContext} from '../../authentication';
import {useDrawer} from '../../../js/hooks/context/DrawerContext';
import {ButtonComponent} from '../../../components/ui/buttons';
import {LoginButton} from '../../authentication/components/LoginButton';
import {Divider} from '../../../components/ui/Divider';
import {createMembership} from '../api/cardholders';
import {Form, Input} from '../../../components/forms/formStyles';
import {StandardSection} from '../../../layout/sections/StandardSection';
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import Confirmation_v2 from "../../../pages/Confirmation_v2.jsx";
import CardholderPage from "../pages/CardholderPage.jsx";

export const MemberSignupForm = () => {

  const { isAuthenticated, user, token, setNewUser, externalLogin } = useContext(AuthContext); 
  /**
   * STATES
   * 
   * 
   */
  
  const date = new Date(user && user?.date_of_birth);
  const birthDay = date.getDate();
  const birthMonth = date.getMonth() + 1; // Months are zero-based, so add 1
  const birthYear = date.getFullYear();
  const [firstName, setFirstName] = useState(user ? user.first_name : '');
  const [lastName, setLastName] = useState(user ? user.last_name : '');
  const [city, setCity] = useState();

  const [email, setEmail] = useState(user ? user.email : '');
  const [instagram, setInstagram] = useState(user ? user?.user_contact_details?.instagram : '');
  const [tiktok, setTikTok] = useState(user ? user?.user_contact_details?.tiktok : '');

  const [mobile, setMobile] = useState(user ? user?.user_contact_details?.mobile : '');
  const [day, setDay] = useState(user && birthDay);
  const [month, setMonth] = useState(user && birthMonth);
  const [year, setYear] = useState(user && birthYear);
  const [gender, setGender] = useState(user ? user.gender : '');
  const [showForm, setShowForm] = useState(isAuthenticated && 'none');
  const [confirmOption, setConfirmOption] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [updateButtonText, setUpdateButtonText] = useState('Update Details');
  const [resMessage, setResMessage] = useState('');

  const formRef = useRef();
  const params = useParams();
  const { signUp, selectedTier, card, setMember } = useCard();
  const { closeDrawer, openDrawer, isOpen, openPreviousDrawer } = useDrawer();
  const navigate = useNavigate();
  const { showErrorToast } = useHandleError();

  const handleSuccessfulSignup = (data) => {
    toast.success(data.message || data?.next_actions?.message || 'Successfully signed up!');
    
    if (data.data) {
      // Store member data in session or local storage
      setSessionStorageJSON('member', data.data)
      setMember(data.data)
    }

    if(!isAuthenticated) {
      const loginAttempt = externalLogin(data?.authorisation, data?.user)

      if(!loginAttempt) {
        showErrorToast('Failed to log you in!');
        setIsLoading(false)
      }
    }



    if(data?.next_actions) {

      setSessionStorageJSON('message', {
        title: data?.next_actions.title,
        message: data?.next_actions.messsage
      })

      if (data.next_actions.redirect) {

        // Close the drawer
        closeDrawer();

        // Perform the redirection
        navigate(data.next_actions.redirect)


      }

      if (data.next_actions.openDrawer) {
        // Logic to open another drawer
        openDrawer(<Confirmation_v2 propId={data.data.id} propType={'card'} />, )
        return;
      }

    }

    if(isOpen && data.status === 'info' && data.data) {
      openDrawer(<CardholderPage cardholder={data.data}/> );
      return;
    }
    


    closeDrawer()
    return true;
  };

  const createMemberMutation = useMutation({
    mutationFn: createMembership,
    onSuccess: (data) => {
      handleSuccessfulSignup(data);
      setIsLoading(false)
    },
    onError: (error) => {
      setIsLoading(false)
      showErrorToast(error?.response?.data?.message || error)
      console.log('Error', error.message);
    }
  });

  const handleSubmit = (e) => {
      e.preventDefault()

      setIsLoading(true)

      if(!selectedTier) {
        setIsLoading(false)
        toast.warning('You must select a tier')
      }
      
      const formData = new FormData(e.currentTarget);
      formData.append('card', card.id);
      formData.append('tier', selectedTier.id);
      formData.append('drawerIsOpen', isOpen)
    console.log('Form Data', formData)
      createMemberMutation.mutate(formData)
  }

  return (

    <StandardSection style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>

      { !isAuthenticated &&
        <>
          <LoginButton login returnLocation={window.location.pathname} />
          <DividerWithText text={'OR'} />
        </>

      }


    <Form display={'flex'} ref={formRef} onSubmit={handleSubmit} autoComplete='on' id='customer-info-form'>

          <>
          </>
            <Input
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            type='text'
            name='first_name'
            placeholder='First Name'
            autoComplete='first_name'
            required ></Input>

      <Input
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          type='text'
          name='last_name'
          placeholder='Last Name'
          autoComplete='last_name'
          required ></Input>


      <Input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type='email'
          // pattern='[0-9]'
          // inputMode='numeric'
          name='email'
          placeholder='Email'
          autoComplete='email'
          required ></Input>
      <Divider/>

          <Input
          onChange={(e) => setTikTok(e.target.value)}
          value={tiktok}
          type='text'
          name='tiktok'
          placeholder='TikTok'
          autoComplete='tiktok'
          required ></Input>

          <Input
          onChange={(e) => setInstagram(e.target.value)}
          value={instagram}
          type='text'
          name='instagram'
          placeholder='Instagram'
          autoComplete='instagram'
          required ></Input>

      <Divider/>

      <Input
          onChange={(e) => setCity(e.target.value)}
          value={city}
          type='text'
          name='city'
          placeholder='City'
          autoComplete='city'
          required ></Input>

          <Divider/>

          <ButtonComponent
          variant={'solid'}
          text={'SIGN UP NOW'}
          onClick={() => setConfirmOption('register')}
          isLoading={isLoading}
          />


        </Form>  
    </StandardSection>
      )
}
