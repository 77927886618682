import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {useNavigate, useParams} from "react-router-dom";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {Loading} from "../../../components/ui/Loading.jsx";
import {FullPageContainer} from "../../../layout/styles/layoutStyles.js";
import {HeroImage} from "../../../components/heroes/HeroImage.jsx";
import {updateTheme} from "../../themes/js/utils.js";
import {RectangleCardComponent} from "../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent.jsx";
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import {ProductList} from "../../ecommerce/products/components/index.js";
import GuestlistForm from "../components/GuestlistForm.jsx";
import {useEvent} from "../js/hooks/index.js";
import {Footer} from "../../../layout/components/Footer.jsx";

const PageContainer = styled(FullPageContainer)`
    padding-top: 0;
`

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    width: 100%;
    gap: 20px;
    padding: 0 20px;
`

const ProductListContainer = styled.div`
    width: 100%;
    padding: 20px 20px 40px;
`

const EventHome = () => {
    const params = useParams();
    const slug = params.slug;
    const { showErrorPage } = useHandleError();
    const { setEvent, event } = useEvent();
    const [events, setEvents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const { isLoading, isSuccess, error, data } = useApiQuery(`events?business=${slug}`, (data) => {
        setEvent(data?.data[0]);
        setEvents(data?.data);
    });

    useEffect(() => {
        if (data && data.data) {
            setEvents(data.data);
            setEvent(data.data[0]);
        }
    }, [data]);

    if (isLoading) return <Loading />;
    if (error) showErrorPage(error);
    if (!isSuccess) showErrorPage(error);
    if (!event) return null;

    updateTheme(events?.[0]?.theme);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredEvents = events.filter(event =>
        event.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <PageContainer>
                <HeroImage image={data.data?.[0]?.image.cdnUrl} />

                <ContentContainer style={{ marginTop: '-125px', gap: '5px', zIndex: 10 }}>

                    <Paragraph>{events[0]?.short_description}</Paragraph>
                    <input
                        type="text"
                        placeholder="Search cities"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        style={{
                            padding: '10px',
                            width: '100%',
                            marginBottom: '20px',
                            borderRadius: 'var(--border-radius-default)',
                            border: '1px solid',
                            borderImage: 'var(--accent-colour) 1',
                            backgroundColor: 'var(--primary-colour)'
                        }}
                    />

                    {filteredEvents.map(event => (
                        <RectangleCardComponent
                            key={event.id}
                            image={<ImageComponent src={event?.image?.cdnUrl} width={100} />}
                            title={`${event.name}`}
                            description={event.dates.startDate}
                            handleClick={() => navigate(`/e/${event.slug}`)}
                        />
                    ))}
                </ContentContainer>
            </PageContainer>
            <Footer />
        </>
    );
};

export default EventHome;